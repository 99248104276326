import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefreshSession, fetchSession } from "store/session/actions";
import LayoutWrapper from "components/LayoutWrapper";
import Fallback from "../components/Fallback";
// import Layout from "../layouts";

const PrivateRoute = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const session = useSelector((globalState) => globalState.session);

  useEffect(() => {
    if (isEmpty(session.user)) dispatch(fetchRefreshSession());
    else dispatch(fetchSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (session.loading) {
    return <Fallback active />;
  }

  if (isEmpty(session.user)) {
    return <Redirect to="/auth/login" />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LayoutWrapper leftNavigationType={props.leftNavigationType}>
      <Route key={props.path} path={props.path} exact={props.exact}>
        {React.cloneElement(children, props)}
      </Route>
    </LayoutWrapper>
  );
};

export default PrivateRoute;
