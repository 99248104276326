export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export const isProduction = ENVIRONMENT === 'PRODUCTION';

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const MIX_PANEL_KEY = process.env.REACT_APP_MIX_PANEL_KEY;

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

