import React from 'react';
import styles from './styles.module.less';
import loading from 'static/loading.svg';
import logo from 'static/gravity-icon.png';

const Fallback = ({ showLogo } = {
  showLogo: true
}) => {

  return (
    <div className={styles.fallback}>
      { showLogo && <img src={logo} alt="logo" width="70" style={{ transform: 'rotate(-10deg)'}} /> }
      <img src={loading} className={styles.loading} alt="loading" width="100"/>
    </div>
  )
}

export default Fallback;