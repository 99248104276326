import React from "react";

const Auth = React.lazy(() => import("../views/Auth/index"));
const SettingsView = React.lazy(() => import("../views/Settings/index"));
const OauthCallBackView = React.lazy(() =>
  import("../views/OauthCallbacks/index")
);
const PortalsView = React.lazy(() => import("../views/Portals/index"));
const MainSettingsView = React.lazy(() => import("../views/MainSettings/index.jsx"));
const DatabaseView = React.lazy(() => import("../views/Database/index"));
// const TicketsView = React.lazy(() =>
//   import("../views/Workflows/components/WorkflowView/Tickets/index")
// );
const WorkflowsView = React.lazy(() => import("../views/Workflows/index"));
const LayoutView = React.lazy(() => import("../views/Layout/index"));
const ClientUserAuthenticationView = React.lazy(() =>
  import("../views/ClientUserAuthentication/index")
);
const IntegrationsView = React.lazy(() =>
  import("../views/Integrations/index")
);

const routes = {
  auth: {
    path: "/auth",
    component: Auth,
    roles: [],
    exact: false,
    leftNavigationType: null,
    routes: {
      login: {
        exact: true,
        path: "/login",
      },
      register: {
        exact: true,
        path: "/register",
      },
    },
  },
  settings: {
    path: "/settings",
    component: MainSettingsView,
    leftNavigationType: null,
    roles: ["USER"],
    exact: false,
  },
  portalSettings: {
    path: "/:portalSlug/settings",
    component: SettingsView,
    leftNavigationType: "portalNav",
    roles: ["USER"],
    exact: false,
  },
  oauthCallbacks: {
    path: "/oauthCallback",
    leftNavigationType: null,
    component: OauthCallBackView,
    roles: [],
    exact: false,
  },
  portals: {
    path: "/portals",
    leftNavigationType: null,
    component: PortalsView,
    roles: ["USER"],
    exact: true,
  },
  noWorkflow: {
    path: "/:portalSlug/workflows/",
    leftNavigationType: "portalNav",
    component: WorkflowsView,
    roles: ["USER"],
    exact: true,
  },
  workflows: {
    path: "/:portalSlug/workflows/:workflowSlug",
    leftNavigationType: "portalNav",
    component: WorkflowsView,
    roles: ["USER"],
    exact: true,
  },
  layout: {
    path: "/:portalSlug/layout",
    leftNavigationType: "portalNav",
    component: LayoutView,
    roles: ["USER"],
    exact: true,
  },
  // tickets: {
  //   path: "/:portalSlug/tickets",
  //   leftNavigationType: "portalNav",
  //   component: TicketsView,
  //   roles: ["USER"],
  //   exact: true,
  // },
  database: {
    path: "/:portalSlug/database",
    leftNavigationType: "portalNav",
    component: DatabaseView,
    roles: ["USER"],
    exact: false,
  },
  customer: {
    path: "/:portalSlug/customer",
    leftNavigationType: "portalNav",
    component: ClientUserAuthenticationView,
    roles: ["USER"],
    exact: false,
  },
  integrations: {
    path: "/:portalSlug/integrations",
    leftNavigationType: "portalNav",
    component: IntegrationsView,
    roles: ["USER"],
    exact: false,
  },
};

export default routes;
