import React from "react";
import { Route } from "react-router-dom";

const PublicRoute = ({ children, ...props }) => {
  return (
    <Route key={props.path} path={props.path} exact={props.exact}>
      {children}
    </Route>
  );
};

export default PublicRoute;
