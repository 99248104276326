import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import "antd/dist/antd.min.css";
import "styles/global.less";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
