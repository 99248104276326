import Routes from "routes";
import ReduxWrapper from "./store/index";

function App() {
  return (
    <ReduxWrapper>
      <Routes />
    </ReduxWrapper>
  );
}

export default App;
