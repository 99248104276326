import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import _ from "lodash";
import routes from "./routes.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import PublicRoute from "./PublicRoute.jsx";
import { isProduction } from "constants/configs";
import Fallback from "../components/Fallback";

const App = () => {
  return (
    <Router>
      <Switch>
        {_.map(routes, (route) => {
          const Component = route.component;
          const allowedRoles = _.get(route, "roles", []);
          const RouteForPath =
            allowedRoles.length > 0 ? PrivateRoute : PublicRoute;
          return (
            <RouteForPath
              key={route.path}
              path={route.path}
              exact={route.exact}
              leftNavigationType={route.leftNavigationType}
            >
              <React.Suspense
                fallback={() => {
                  <Fallback />;
                }}
              >
                <Component path={route.path} />
              </React.Suspense>
            </RouteForPath>
          );
        })}
        <PublicRoute path="/" exact>
          {!isProduction ? (
            <Redirect to="/auth/login" />
          ) : (
            <Redirect to="/auth/login" />
          )}
        </PublicRoute>
      </Switch>
    </Router>
  );
};

export default App;
