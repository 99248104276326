import React from "react";
import { Menu, message } from "antd";
import styles from "../layoutWrapper.module.less";
import { useHistory, useLocation } from "react-router-dom";
import { getUserLogoutRequest } from "../requests";

const navItems = [
  {
    name: "Layout",
    key: "layout",
    icon: <i className="ri-layout-5-line" style={{ fontSize: "20px" }} />,
    filledIcon: (
      <i className="ri-layout-5-fill" style={{ fontSize: "20px" }} />
    ),
  },
  {
    name: "Workflows",
    key: "workflows",
    icon: <i className="ri-layout-grid-line" style={{ fontSize: "20px" }} />,
    filledIcon: (
      <i className="ri-layout-grid-fill" style={{ fontSize: "20px" }} />
    ),
  },
  {
    name: "Database",
    key: "database",
    icon: <i className="ri-database-2-line" style={{ fontSize: "20px" }} />,
    filledIcon: (
      <i className="ri-database-2-fill" style={{ fontSize: "20px" }} />
    ),
  },
  // {
  //   name: "Tickets",
  //   key: "tickets",
  //   icon: <i className="ri-task-line" style={{ fontSize: "20px" }} />,
  //   filledIcon: <i className="ri-task-fill" style={{ fontSize: "20px" }} />,
  // },
  {
    name: "Customer",
    key: "customer",
    icon: <i className="ri-user-add-line" style={{ fontSize: "20px" }} />,
    filledIcon: <i className="ri-user-add-fill" style={{ fontSize: "20px" }} />,
  },
  {
    name: "Integrations",
    key: "integrations",
    icon: <i className="ri-git-branch-line" style={{ fontSize: "20px" }} />,
    filledIcon: (
      <i className="ri-git-branch-fill" style={{ fontSize: "20px" }} />
    ),
  },
  {
    name: "Settings",
    key: "settings",
    icon: <i className="ri-settings-5-line" style={{ fontSize: "20px" }} />,
    filledIcon: (
      <i className="ri-settings-5-fill" style={{ fontSize: "20px" }} />
    ),
  },
];

const LeftSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const stack = location.pathname.split("/") || [];
  const currentSelection = navItems.find((nav) => stack[2] === nav.key).key;

  const handleNavigationClick = async (key) => {
    const totalBacks = stack.length - 3;
    let back = "../";
    let backString = "./";
    for (let i = 0; i < totalBacks; i++) {
      backString = backString + back;
    }
    if (key !== "logout") {
      history.push(`${backString}${key}`);
    } else {
      try {
        const res = await getUserLogoutRequest();
        if (res.data.success) history.push("/auth/login");
        else message.error("Some Error Occured");
      } catch (err) {
        message.error("Some Error Occured");
      }
    }
  };

  return (
    <>
      <div className={styles.navSection}>
        <Menu
          mode="inline"
          selectedKeys={[currentSelection]}
          onClick={({ key }) => handleNavigationClick(key)}
        >
          {navItems.map((nav) => (
            <Menu.Item key={nav.key}>
              <div className={styles.nav}>
                <div className={styles.navIcon}>
                  {nav.key === currentSelection ? nav.filledIcon : nav.icon}
                </div>
                <div className={styles.navName}>{nav.name}</div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div></div>
    </>
  );
};

export default LeftSidebar;
