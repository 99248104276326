import React from "react";
import { Layout, message, Popover } from "antd";
import logo from "static/gravity-icon.png";
import userBlank from "static/user-blank.png";
import styles from "./layoutWrapper.module.less";
import LeftSidebar from "./components/LeftSidebar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserLogoutRequest } from "./requests";

const { Sider, Content, Header } = Layout;

const getSidebarBasedOnType = (navigationType) => {
  switch (navigationType) {
    case "portalNav":
      return (
        <Sider
          className={styles.navBar}
          width={80}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <LeftSidebar />
        </Sider>
      );
    // case "workspaceNav":
    //   return (
    //     <Sider
    //       className={styles.navBar}
    //       width={60}
    //       breakpoint="lg"
    //       collapsedWidth="0"
    //     ></Sider>
    //   );
    default:
      return null;
  }
};

const LayoutWrapper = ({ children, leftNavigationType }) => {
  const sessionUserData = useSelector(
    (globalState) => globalState.session.user
  );
  const { firstName, lastName, email, profileImageUrl } = sessionUserData;
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const res = await getUserLogoutRequest();
      if (res.data.success) history.push("/auth/login");
      else message.error("Some Error Occured");
    } catch (err) {
      message.error("Some Error Occured");
    }
  };
  return (
    <Layout className={styles.layout}>
      <Header className={styles.topHeader}>
        <div className={styles.leftHeader}>
          <div className={styles.logo} onClick={() => history.push("/portals")}>
            <img src={logo} alt="" /> 
            <p>FrontBase</p>
          </div>
          <div className={styles.planInfomation}>
            <p>Free Plan</p>
          </div>
        </div>
        <Popover
          className={styles.profileNav}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement="bottomRight"
          content={() => (
            <div className={styles.menuOptions}>
              <div
                className={styles.option}
                onClick={() => history.push("/settings")}
              >
                <i className="ri-settings-3-line" />Settings
              </div>
              <div className={styles.option} onClick={handleLogout}>
                <i className="ri-logout-circle-r-line" /> Logout
              </div>
            </div>
          )}
          title={() => {
            return (
              <div className={styles.userPopoverTitle}>
                <img
                  className={styles.userImage}
                  src={profileImageUrl ? profileImageUrl : userBlank}
                  alt=""
                />
                <div>
                  <div>{`${firstName} ${lastName}`}</div>
                  <div className={styles.userEmail}>{email}</div>
                </div>
              </div>
            );
          }}
          trigger="click"
          arrowPointAtCenter={true}
        >
          <img
            className={styles.profileImage}
            src={profileImageUrl ? profileImageUrl : userBlank}
            alt=""
          />
        </Popover>
      </Header>
      <Layout>
        {getSidebarBasedOnType(leftNavigationType)}
        <Layout>
          <Content className={styles.mainScreen}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default React.memo(LayoutWrapper);
